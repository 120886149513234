<template>
<q-layout view="hHh Lpr lff">
    <q-page-container>
        <router-view></router-view>
    </q-page-container>
</q-layout>
</template>
<script>
import "./assets/css/main.css"
export default {
    name: 'App',
    mounted(){
   
    }
} 

</script>
