import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    component: () => import("../nav/navBar.vue"),
    children: [
      { path: "/", component: () => import('../views/Home.vue') },
      { path: "/ecosystem", component: () => import('../views/GameEcosystem.vue') },
      { path: "/staytuned", component: () => import('../views/StayTuned.vue') },
      { path: "/roadmap", component: () => import('../views/RoadMap.vue') },
      { path: "/faq", component: () => import('../views/faq.vue') },
      { path: "/about", component: () => import('../views/AboutPage.vue') },
      { path: "/lightpaper", component: () => import('../views/lightPaper.vue') },
      { path: "/tokenomics", component: () => import('../views/Tokenomics.vue') },
      { path: "/news", component: () => import('../views/News.vue') },
      { path: "/gallery", component: () => import('../views/Gallery.vue') },
      { path: "/collaboration", component: () => import('../views/Collaboration.vue') },
      { path: "/news/:story", component: () => import('../views/Story.vue') },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
